import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { LoincCodingCode } from "src/constants/fhir";
import Fhir from "src/lib/fhir";
import { getSupportedUserLocale } from "src/lib/i18next";
import translate from "src/lib/translate";
import LabResultsCubit from "src/state/LabResultsCubit/LabResultsCubit";
import { useBloc } from "@blac/react";
import { TranslationKey } from "src/types/translationKey";
import Collection from "src/ui/components/StyledComponents/Collection";
import CollectionItemLink from "src/ui/components/StyledComponents/CollectionItemLink";
import CollectionTitle from "src/ui/components/StyledComponents/CollectionTitle";

// Add custom names for combination of codes
const CUSTOM_TITLES: [string[], string][] = [
  [[LoincCodingCode.stepsInDay], translate("observations.stepsInDay")],
  [
    [
      LoincCodingCode.bloodGlucoseCapillary,
      LoincCodingCode.bloodGlucoseCapillaryFasting
    ],
    translate("bloodGlucose")
  ]
];

const ListLabsByCodes: FC = () => {
  const params = useParams<{ codes?: string; "*": string }>();
  const [{ observations }, { loadObservations }] = useBloc(LabResultsCubit);

  const codes = params.codes?.split(",") ?? [];

  useEffect(() => {
    void loadObservations();
  }, []);

  const listObservations = useMemo(() => {
    const filtered = Fhir.filterObservations(observations ?? [], {
      codes: codes
    });
    return Fhir.sortObservationsByEffectiveDate(filtered);
  }, [observations, codes]);

  const titles = useMemo<string[]>(() => {
    const codesSorted = Fhir.sortLoincCodes(codes);
    for (const [nameCodes, title] of CUSTOM_TITLES) {
      if (Fhir.sortLoincCodes(nameCodes).join(",") === codesSorted.join(",")) {
        return [title];
      }
    }

    // fallback use the titles from the observations
    const set = new Set<string>();
    listObservations.forEach((obs) => {
      const parsed = Fhir.autoParseObservation(obs);
      if (!parsed) return;
      const { title } = parsed;
      set.add(title);
    });

    return Array.from(set);
  }, [listObservations]);

  const limitedObservations = listObservations.slice(0, 80);

  return (
    <>
      <CollectionTitle>
        {titles.map((e, i) => (
          <div key={e}>
            {e}
            {i !== titles.length - 1 ? "," : ""}
          </div>
        ))}
      </CollectionTitle>
      <Collection>
        {limitedObservations.map((obs) => {
          const parsed = Fhir.autoParseObservation(obs);
          if (!parsed) return null;
          const local = Fhir.localObservationData(parsed);
          const { value, unit, date, category, code } = local;

          const unitText = translate(
            "observationUnits",
            {
              context: unit,
              count: parseInt(value, 10)
            },
            unit as TranslationKey
          );

          const datetime = date
            .toDate()
            .toLocaleString(getSupportedUserLocale(), {
              month: "long",
              day: "numeric",
              year: "numeric",
              hour: local.hasTime ? "numeric" : undefined,
              minute: local.hasTime ? "numeric" : undefined,
              hour12: true
            });

          // add Fasting to the category if the code is blood glucose
          if (
            code === (LoincCodingCode.bloodGlucoseCapillaryFasting as string)
          ) {
            category.push(translate("fasting"));
          }

          return (
            <CollectionItemLink
              key={`${obs.id}`}
              label={
                <span>
                  <span>{value}</span>
                  <span style={{ margin: "0 0.5em 0 0.3em" }}>{unitText}</span>
                </span>
              }
              status={<small>{datetime}</small>}
              detail={
                <span>
                  {category.map((c) => (
                    <nine-pill key={c}>{c}</nine-pill>
                  ))}
                </span>
              }
            />
          );
        })}
      </Collection>
    </>
  );
};

export default ListLabsByCodes;
